<template>
  <div class="pay">
    <div class="container box">
      <div class="order_info">
        <div class="head">
          <p>万慧教育-课程结算支付</p>
          <span><i class="iconfont">&#xe615;</i>问题指引</span>
        </div>

        <!-- 支付信息 -->
        <template v-if="!success">
          <div class="main">
            <p class="order_num">
              商品订单：<span>{{ orderInfo.orderNo }}</span>
            </p>
            <p class="order_price">
              支付金额：<span
                ><span style="font-size: 18px">￥</span
                >{{ orderInfo.price }}</span
              >
            </p>
            <el-collapse v-model="activeNames">
              <el-collapse-item title="订单详情" name="1">
                <p>商品名称：{{ orderInfo.productName }}</p>
                <p>支付订单：{{ orderInfo.orderNo }}</p>
                <p>应付金额：￥{{ orderInfo.price }}</p>
                <p>购买时间：{{ orderInfo.payTime }}</p>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="code_area">
            <div class="qrcode">
              <div ref="qrCodeUrl"></div>
              <p class="tips">
                打开手机{{
                  $route.query.payChannel == "wxpay" ? "微信" : "支付宝"
                }}，扫一扫完成支付
              </p>
            </div>
          </div>
        </template>

        <!-- 支付成功 -->
        <div class="pay_success" v-else>
          <img src="@/assets/img/emptyIcon/goumaichengong@2x.png" alt="" />
          <p>购买成功</p>
          <div class="btns">
            <button @click="$router.push('/')">返回首页</button>
            <button
              @click="
                $router.push(`/courseDetail?courseId=${$route.query.courseId}`)
              "
            >
              查看课程
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addOrder, orderStatus } from "@/api/curriculum";
import QRCode from "qrcodejs2";

export default {
  name: "pay",
  created() {
    let params = this.$route.query;
    this.addOrder(params);
  },
  methods: {
    // 新增订单
    addOrder(params) {
      addOrder(params).then((res) => {
        if (res.code == 200) {
          this.orderInfo = res.data;
          this.creatQrCode();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 生成二维码
    creatQrCode() {
      new QRCode(this.$refs.qrCodeUrl, {
        text: this.orderInfo.qrCode,
        width: 184,
        height: 184,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });

      this.myInterval = window.setInterval(() => {
        setTimeout(this.paySuccess(), 0);
      }, 2000);
    },
    // 轮询支付状态
    paySuccess() {
      orderStatus({ orderId: this.orderInfo.orderId }).then((res) => {
        if (res.code == 200) {
          if (res.status == 1) {
            // 已支付
            this.success = true;
            clearInterval(this.myInterval);
            this.myInterval = null;
          } else {
            // 未支付
            // let params = this.$route.query;
            // this.addOrder(params);
          }
          // 成功
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.myInterval);
    this.myInterval = null;
  },
  data() {
    return {
      orderInfo: {},
      activeNames: "",
      success: false,
      myInterval: null,
    };
  },
};
</script>

<style lang="less" scoped>
.pay {
  padding-top: 30px;
  padding-bottom: 50px;
  min-height: calc(100vh - 66px - 200px);
  background-color: #f3f3f3;
  .box {
    padding: 55px 314px 74px;
    background-color: #fff;
    border-radius: 6px;
    .order_info {
      width: 572px;
      // height: 557px;
      border: solid 1px #e8e8e8;
      .head {
        position: relative;
        padding: 0 29px;
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: #f6f6f6;
        p {
          font-size: 18px;
          text-align: left;
        }
        span {
          position: absolute;
          top: 50%;
          right: 23px;
          transform: translateY(-50%);
          color: #ff8a22;
          cursor: pointer;
          .iconfont {
            vertical-align: top;
            margin-right: 5px;
          }
        }
      }
      .main {
        padding: 19px 29px;
        border-bottom: solid 1px #e8e8e8;
        text-align: left;
        .order_num {
          span {
            color: #999;
          }
        }
        .order_price {
          display: inline-block;
          margin-top: 15px;
          vertical-align: top;
          span {
            font-size: 24px;
            color: #ff8a22;
          }
        }
        ::v-deep.el-collapse {
          margin-top: 6px;
          margin-left: 20px;
          display: inline-block;
          border: none;
          .el-collapse-item__header,
          .el-collapse-item__wrap {
            border: none;
            &.el-collapse-item__header {
              font-size: 14px;
              color: #999;
            }
            &.el-collapse-item__wrap {
              margin-left: -80px;
            }
          }
          .el-collapse-item__arrow,
          .el-collapse-item__arrow.is-active {
            margin-left: 10px;
          }
          .el-collapse-item__content {
            padding: 0;
            p {
              font-size: 14px;
              color: #999;
              line-height: 2.5;
            }
          }
        }
      }
      .code_area {
        position: relative;
        height: 268px;
        text-align: center;
        .qrcode {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          .tips {
            line-height: 3;
            color: #999;
            font-size: 12px;
          }
        }
      }

      .pay_success {
        padding: 105px 0 167px;
        p {
          font-size: 16px;
          line-height: 2.5;
        }
        .btns {
          margin-top: 25px;
          button {
            width: 150px;
            height: 40px;
            background-color: #fff;
            color: #0d79ff;
            border: 1px solid #0d79ff;
            border-radius: 20px;
            &:last-child {
              margin-left: 21px;
              color: #fff;
              background-color: #0d79ff;
            }
          }
        }
      }
    }
  }
}
</style>